




















































































import 'vue-material-design-icons/styles.css'
import MonitorIcon from 'vue-material-design-icons/MonitorScreenshot'
import LeftIcon from 'vue-material-design-icons/ChevronLeftCircle'
import RightIcon from 'vue-material-design-icons/ChevronRightCircle'
import PlusIcon from 'vue-material-design-icons/PlusCircle'
import MinusIcon from 'vue-material-design-icons/MinusCircle'
import Flipbook from './Flipbook'
import ChevronRight from 'vue-material-design-icons/ChevronRight.vue';
import ArrowLeftRight from 'vue-material-design-icons/ArrowLeftRight.vue';
import Modal from '@/components/Modal.vue'

export default
  name: 'app'
  components: {MonitorIcon, Modal, ArrowLeftRight, ChevronRight, Flipbook, LeftIcon, RightIcon, PlusIcon, MinusIcon }
  data: ->
    showModal:true
    menu: [
      {
        header: true,
        title: 'Catalogo Replica',
        hiddenOnCollapse: true
        hidden: true
        class: ''
        attributes: {}
      },
      {
        href: '/#2',
        title: 'Conócenos',
        icon: {
          element:'span',
          class:'icon-bar icon-res'
        }
      },
      {
        href: '/#3',
        title: 'Software',
        icon: {
          element:'span',
          class:'icon-bar icon-soft'
        }
        child: [
          {
            href: '/#3',
            title: 'Autodesk'
          },
          {
            href: '/#4',
            title: 'Adobe'
          },
          {
            href: '/#5',
            title: 'Microsoft'
          }
        ]
      },
      {
        href: '/#7',
        title: 'Hardware',
        icon: {
          element:'span',
          class:'icon-bar icon-hard'
        }
      },
      {
        href: '/#8',
        title: 'Soluciones TI',
        icon: {
          element:'span',
          class:'icon-bar icon-sol'
        },
        child: [
          {
            href: '/#9',
            title: 'Genesys ERP'
          }
          ]
      },
      {
        href: '/#9',
        title: 'Arrendamiento y Financiamiento',
        icon: {
          element:'span',
          class:'icon-bar icon-ayf'
        }
      },
      {
        href: '/#10',
        title: 'Capacitaciones',
        icon: {
          element:'span',
          class:'icon-bar icon-cap'
        }
      }
    ],
    pages: [],
    pagesHiRes: [],
    hasMouse: true
    pageNum: null
  methods:
    fullScreen: ->
        elem = document.getElementById("app")
        if (elem.requestFullscreen) then elem.requestFullscreen()

    onFlipLeftStart: (page) -> console.log 'flip-left-start', page
    onFlipLeftEnd: (page) ->
      console.log 'flip-left-end', page
      window.location.hash = '#' + page
    onFlipRightStart: (page) -> console.log 'flip-right-start', page
    onFlipRightEnd: (page) ->
      console.log 'flip-right-end', page
      window.location.hash = '#' + page
    onZoomStart: (zoom) -> console.log 'zoom-start', zoom
    onZoomEnd: (zoom) -> console.log 'zoom-end', zoom
    setPageFromHash: ->
      n = parseInt window.location.hash.slice(1), 10
      @pageNum = n if isFinite n
  mounted: ->
    window.addEventListener 'keydown', (ev) =>
      flipbook = @$refs.flipbook
      return unless flipbook
      flipbook.flipLeft() if ev.keyCode == 37 and flipbook.canFlipLeft
      flipbook.flipRight() if ev.keyCode == 39 and flipbook.canFlipRight

    # Simulate asynchronous pages initialization
    setTimeout (=>
      @pages = [
        null
        'images/catalogo/CatalogoReplica-PAG1.jpg'
        'images/catalogo/CatalogoReplica-PAG2.jpg'
        'images/catalogo/CatalogoReplica-PAG3.jpg'
        'images/catalogo/CatalogoReplica-PAG4.jpg'
        'images/catalogo/CatalogoReplica-PAG5.jpg'
        'images/catalogo/CatalogoReplica-PAG6.jpg'
        'images/catalogo/CatalogoReplica-PAG7.jpg'
        'images/catalogo/CatalogoReplica-PAG8.jpg'
        'images/catalogo/CatalogoReplica-PAG9.jpg'
        'images/catalogo/CatalogoReplica-PAG10.jpg'
        'images/catalogo/CatalogoReplica-PAG12.jpg'
      ]
      @pagesHiRes = [
        null
        'images-large/CatalogoReplica-PAG1-full.jpg'
        'images-large/CatalogoReplica-PAG2-full.jpg'
        'images-large/CatalogoReplica-PAG3-full.jpg'
        'images-large/CatalogoReplica-PAG4-full.jpg'
        'images-large/CatalogoReplica-PAG5-full.jpg'
        'images-large/CatalogoReplica-PAG6-full.jpg'
        'images-large/CatalogoReplica-PAG7-full.jpg'
        'images-large/CatalogoReplica-PAG8-full.jpg'
        'images-large/CatalogoReplica-PAG9-full.jpg'
        'images-large/CatalogoReplica-PAG10-full.jpg'
        'images-large/CatalogoReplica-PAG12-full.jpg'
      ]
    ), 1

    window.addEventListener 'hashchange', @setPageFromHash
    @setPageFromHash()
